import React from 'react';
import Card from "../components/Card";
import ExampleChart from "../components/Chart";
const Dashboard = () => {
    const [stats, setStats] = React.useState({
        orders: 0,
        products: 0,
        least_product: {},
        most_product: {},
        earnings: 0,
        stats: [],
        income: []
    })
    React.useEffect(() => {
        fetch("https://tipon.pl/api/stats", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
            }).then(response => response.json())
            .then(data => {
                setStats(data)
            }).catch(err => {
                console.log(err)
            })
    }, [])
    function transformAPIData(ordersData, incomeData) {
        const months = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];

        if (!Array.isArray(ordersData) || !Array.isArray(incomeData)) {
            return [];
        }
        return months.map((month, index) => ({
            name: month,
            zakupów: ordersData[index] || 0,
            zarobki: incomeData[index] || 0,
        }));
    }
    const transformedData = transformAPIData(stats.stats, stats.income);
    return (
        <>
            <h2 className="text-3xl font-semibold mb-6">Strona główna</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
                <Card icon="shopping_cart" title="Ilość zamówień" value={stats.orders?stats.orders:0} />
                <Card icon="inventory" title="Ilość produktów" value={stats.products?stats.products:0} />
                <Card icon="thumb_down" title="Najmniej kupowane" value={stats.least_product?stats.least_product.name:"Brak"} />
                <Card icon="star" title="Najczęściej kupowane" value={stats.most_product?stats.most_product.name:"Brak"} />
                <Card icon="attach_money" title="Zarobek" value={stats.earnings} />
            </div>
            <div className="bg-gray-800 p-5 rounded-lg shadow mt-10">
                <h3 className="text-xl mb-4">Statystyki</h3>
                <ExampleChart data={transformedData}/>
            </div>
        </>
    );
}

export default Dashboard;
