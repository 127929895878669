import React from 'react';
import Modal from 'react-modal';

const DetailsModal = ({ isOpen, onRequestClose, order }) => {
    if (!order) return null;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Szczegóły zamówienia"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
        >
            <div className="bg-gray-800 text-white rounded-lg p-8 w-full max-w-md">
                <div className="flex items-center mb-4">
                    <div
                        className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10 mr-4">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                             fill="#3b82f6">
                            <path
                                d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                        </svg>
                    </div>
                    <h2 className="ml-4 text-2xl font-semibold">Szczegóły zamówienia #{order.order.id}</h2>
                </div>
                <div className="mb-4">
                    <h3 className="font-semibold">Produkty:</h3>
                    <ul className="list-disc list-inside">
                        {order.products.map(product => (
                            <li key={product.id}>{product.name} x {product.quantity} - {product.price}PLN</li>
                        ))}
                    </ul>
                </div>
                <div className="mb-4">
                    <h3 className="font-semibold">Wiadomość do zamówienia:</h3>
                    <p className="text-gray-400">{order.order.message}</p>
                </div>
                <button
                    onClick={onRequestClose}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                    Zamknij
                </button>
            </div>
        </Modal>
    );
};

export default DetailsModal;
