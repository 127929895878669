import React, {useEffect} from "react";

const Error404 = () => {
    useEffect(() => {
        const audio = new Audio('/troll.mp3');
        audio.muted = true;
        window.addEventListener('mousemove', () => {
            document.getElementById('error404').click();
            audio.play().then(() => {
                audio.muted = false;
            }).catch((error) => {
                console.error(error);
            });

        })

        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, []);

    return (
        <div className="w-full h-screen flex items-center justify-center bg-black">
            <img src="https://http.cat/404.jpg" id="error404" alt="404 Not Found" className="h-full object-cover"/>
        </div>
    );
}

export default Error404;