import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

export default function ExampleChart({data}) {

    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" strokeOpacity={0.2} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="zakupów" stroke="#7e7ce9" />
                <Line type="monotone" dataKey="zarobki" stroke="#fe5b7e" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
}