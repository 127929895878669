import React from 'react';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify'
function Login() {
    const navigate = useNavigate();
    if (localStorage.getItem('token')) {
        navigate("/")
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.target[0].value
        const password = e.target[1].value
        fetch("https://tipon.pl/api/auth/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email":email,
                "password":password
            })
        }).then((response) => response.json())
            .then((data) => {

                if  (data.error) {
                    toast.error("Nieprawidłowy login lub hasło")
                }
                if (data.token) {
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('firstname', data.user.firstName)
                    localStorage.setItem('lastname', data.user.lastName)
                    localStorage.setItem('email', data.user.email)
                    localStorage.setItem('avatar', data.user.avatar)
                    navigate('/')
                }
            })
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 overflow-hidden relative">
            <div className="bg-gray-800 p-10 rounded-lg shadow-lg w-full max-w-md z-10">
                <div className="flex items-center justify-center mb-6 animate-bounce">
                    <img src="https://cms.mscode.pl/uploads/image_1_57f1319056.png" className="w-16 h-16 mr-3" />
                    <span className="text-4xl font-bold text-blue-500">Tipon.pl</span>
                </div>
                <h2 className="text-3xl font-semibold text-white mb-6 text-center">Logowanie</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                            id="email"
                            type="email"
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="password">
                            Hasło
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                            id="password"
                            type="password"
                            placeholder="Hasło"
                            required
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            type="submit"
                        >
                            Zaloguj się
                        </button>
                    </div>
                </form>
            </div>
            <div className="absolute inset-0 z-0">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 opacity-25 animate-gradient"></div>
            </div>
        </div>
    );
}

export default Login;
