import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
    const [ordersDropdownVisible, setOrdersDropdownVisible] = useState(false);

    return (
        <div className="w-64 h-full bg-gray-800 p-5 rounded-br-xl flex flex-col justify-between sticky top-16">
            <ul className="flex-grow">
                <li className="mb-4">
                    <NavLink
                        to="/"
                        className={({ isActive }) => isActive ? 'sidebar-active flex items-center py-2 px-4 rounded hover:bg-gray-700' : 'flex items-center py-2 px-4 rounded hover:bg-gray-700'}
                    >
                        <span className="material-icons mr-3">dashboard</span>
                        Strona główna
                    </NavLink>
                </li>
                <li className="mb-4">
                    <NavLink
                        to="/product"
                        className={({ isActive }) => isActive ? 'sidebar-active flex items-center py-2 px-4 rounded hover:bg-gray-700' : 'flex items-center py-2 px-4 rounded hover:bg-gray-700'}
                    >
                        <span className="material-icons mr-3">shopping_bag</span>
                        Produkty
                    </NavLink>
                </li>
                <li className="mb-4">
                    <NavLink
                        to="/category"
                        className={({ isActive }) => isActive ? 'sidebar-active flex items-center py-2 px-4 rounded hover:bg-gray-700' : 'flex items-center py-2 px-4 rounded hover:bg-gray-700'}
                    >
                        <span className="material-icons mr-3">folder_open</span>
                        Kategorie
                    </NavLink>
                </li>
                <li className="mb-4 relative">
                    <button onClick={() => setOrdersDropdownVisible(!ordersDropdownVisible)} className="flex items-center w-full py-2 px-4 rounded hover:bg-gray-700 focus:outline-none">
                        <span className="material-icons mr-3">paid</span>
                        Zamówienia
                        <span className={`material-icons ml-auto transition-transform transform duration-300 ${ordersDropdownVisible ? 'rotate-180' : ''}`} id="orders-icon">
              keyboard_arrow_down
            </span>
                    </button>
                    {ordersDropdownVisible && (
                        <div className="absolute left-0 mt-2 w-full bg-gray-700 rounded-lg shadow-lg">
                            <NavLink
                                to="/order"
                                className={({ isActive }) => isActive ? 'sidebar-active block px-4 py-2 rounded-lg hover:bg-gray-600' : 'block px-4 py-2 rounded-lg hover:bg-gray-600'}
                            >
                                Lista zamówień
                            </NavLink>
                            <NavLink
                                to="/payment"
                                className={({ isActive }) => isActive ? 'sidebar-active block px-4 py-2 rounded-lg hover:bg-gray-600' : 'block px-4 py-2 rounded-lg hover:bg-gray-600'}
                            >
                                Lista płatności
                            </NavLink>
                        </div>
                    )}
                </li>
            </ul>
            <ul>
                <li className="mb-4">
                    <NavLink
                        to="/settings"
                        className={({ isActive }) => isActive ? 'sidebar-active flex items-center py-2 px-4 rounded hover:bg-gray-700' : 'flex items-center py-2 px-4 rounded hover:bg-gray-700'}
                    >
                        <span className="material-icons mr-3">settings</span>
                        Ustawienia
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}

export default Sidebar;
