import React from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {toast} from "react-toastify";



const customStyles = {
    table: {
        style: {
            color: '#4A5568',
            backgroundColor: '#4A5568',
        },
    },
    expanderCell: {
        style: {
            flex: '0 0 48px',
        },
    },
    header: {
        style: {
            backgroundColor: '#2D3748',
            color: '#FFFFFF',
            borderRadius: '20px 20px 0px 0px'
        },
    },
    headRow: {
        style: {
            backgroundColor: '#2D3748',
            color: '#FFFFFF',
            borderBottomWidth: '0px',
            borderBottomColor: '#4A5568',
        },
    },
    headCells: {
        style: {
            color: '#FFFFFF',
        },
    },
    cells: {
        style: {
            backgroundColor: '#1A202C',
            color: '#FFFFFF',
            borderBottomColor: '#1A202C',
            padding: '16px',
        },
    },
    rows: {
        style: {
            minHeight: '72px',
            borderBottomWidth: '0px',
            borderBottomStyle: 'none',
            '&:not(:last-of-type)': {
                borderBottomStyle: 'none',
                borderBottomWidth: '0px',
            },
            '&:last-of-type': {
                borderBottomStyle: 'none',
                borderBottomWidth: '0px',
            },
        },
        highlightOnHoverStyle: {
            backgroundColor: '#4A5568',
            color: '#FFFFFF',
            transitionDuration: '0.15s',
            transitionProperty: 'background-color',
            borderBottomColor: '#4A5568',
        },
    },
    pagination: {
        style: {
            backgroundColor: '#1A202C',
            color: '#FFFFFF',
            borderTopWidth: '1px',
            borderTopColor: '#4A5568',
        },
        pageButtonsStyle: {
            color: '#FFFFFF',
            fill: '#FFFFFF',
            '&:hover:not(:disabled)': {
                backgroundColor: '#4A5568',
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: '#4A5568',
            },
        },
    },
};

const paginationComponentOptions = {
    rowsPerPageText: 'Wierszy na stronę',
    rangeSeparatorText: 'z',
    noRowsPerPage: false,
    selectAllRowsItem: false,
};

const CategoryList = () => {
    const [categories, setCategories] = React.useState([])
    const handleDelete = (categoryId) => {
        const category = categories.find(cat => cat.id === categoryId);

        if (category && category.products > 0) {
            return toast.error("Nie można usunąć kategorii do której należą produkty.")
        } else {
            const isConfirmed = window.confirm("Czy napewno chcesz usunąć tą kategorię?");
            if (isConfirmed) {
                fetch(`https://tipon.pl/api/category/${categoryId}`, {
                    method: "DELETE",
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                }).then(response => {
                    if (response.ok) {
                        toast.success("Pomyślnie usunięto kategorię");
                    }
                }).catch(error => {
                    console.error("Error deleting product:", error);
                });
            }
        }
    };
    const columns = [
        {
            name: 'Nazwa kategorii',
            selector: row => (
                <div className="flex items-center">
                    <div>
                        <p className="text-base">{row.name}</p>
                    </div>
                </div>
            ),
            sortable: true,
        },
        {
            name: "ID",
            selector: row => row.id
        },
        {
            name: 'Ilość produktów',
            selector: row => (
                <p>{row.products}</p>
            ),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                <span
                    className={`${row.active === true ? 'inline-flex items-center rounded-md bg-green-200 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20' : 'inline-flex items-center rounded-md bg-red-200 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'}`}>
                <span className="material-icons text-sm mr-1">{`${row.active === true ? 'check' : 'close'}`}</span>
                    {row.active === true ? "AKTYWNA" : "NIEAKTYWNA"}
      </span>
            ),
        },
        {
            name: 'Adres RCON',
            selector: row => (
                <p className="blur-lg font-bold text-base hover:blur-0">{row.rcon_ip}:{row.rcon_port}</p>
            ),
            sortable: true,
        },
        {
            name: 'Akcje',
            cell: row => (
                <div className="flex items-center">
                    <Link to={`/category/edit/${row.id}`} className="mr-2">
                        <button className="text-blue-500 hover:text-blue-700 flex items-center">
                            <span className="material-icons mr-1">edit</span> Edytuj
                        </button>
                    </Link>
                    <button className="text-red-500 hover:text-red-700 flex items-center" onClick={() => handleDelete(row.id)}>
                        <span className="material-icons mr-1">delete</span> Usuń
                    </button>
                </div>
            ),
        },
    ];


    React.useEffect(() => {
        fetch('https://tipon.pl/api/category', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then((response) => response.json())
            .then(data => {
                setCategories(data)
            }).catch(err => {
                console.error('Błąd podczas requestu API:', err)
            })
    }, [])
    return (
        <div className="bg-gray-800 p-5 rounded-lg shadow mt-10">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-3xl font-semibold">Kategorie</h2>
                <Link to="/category/add"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center">
                    <span className="material-icons mr-1 text-lg">add_circle</span> Dodaj
                </Link>
            </div>
            <p className="text-gray-400 mb-4">Lista wszystkich Kategorii</p>
            <DataTable
                columns={columns}
                data={categories}
                customStyles={customStyles}
                pagination
                paginationComponentOptions={paginationComponentOptions}
            />
        </div>
    );
}

export default CategoryList;
