import React from 'react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const CategoryAdd = () => {
    const navigate = useNavigate();
    const [category, setCategory] = React.useState({
        name: "",
        rcon_ip: "",
        rcon_port: "",
        rcon_password: ""
    })
    const handleChange = (e) => {
        const { id, value } = e.target;
        setCategory(prevCategory => ({
            ...prevCategory,
            [id]: value
        }));
    };
    const validate = () => {
        let isValid = true;

        if (!category.name || category.name.length > 50) {
            toast.error('Nazwa Kategorii nie może mieć więcej niż 50 znaków');
            isValid = false;
        }

        if (!category.rcon_ip || !/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(category.rcon_ip)) {
            toast.error('IP RCON musi być poprawnym adresem IP');
            isValid = false;
        }
        const rconPort = parseInt(category.rcon_port, 10);
        if  (!category.rcon_port || !/^\d+$/.test(rconPort)) {
            toast.error('Port RCON musi być liczbą całkowitą');
            isValid = false;
        }
        return isValid;

    }

    const handleSubmit = ( ) => {
        if (!validate()) {
            return
        }
        fetch("https://tipon.pl/api/category", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(category)
        }).then(response => response.json())
            .then(() => {
                toast.success("Kategoria została dodana!")
                navigate('/category')
            }).catch(err => {
                toast.error("Wystąpił nieznany błąd")
                console.log(err)
        })
    }
    return (
        <div className="bg-gray-800 p-10 rounded-lg shadow">
            <h2 className="text-3xl font-semibold mb-6">Dodawanie kategorii</h2>
            <form>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="name">
                        Nazwa Kategorii
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="name"
                        type="text"
                        value={category.name}
                        onChange={handleChange}
                        placeholder="Nazwa Kategorii"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="rconIp">
                        IP RCON
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="rcon_ip"
                        value={category.rcon_ip}
                        onChange={handleChange}
                        type="text"
                        placeholder="IP RCON"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="rconPort">
                        Port RCON
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="rcon_port"
                        type="text"
                        value={category.rcon_port}
                        onChange={handleChange}
                        placeholder="Port RCON"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="rconPassword">
                        Hasło RCON
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="rcon_password"
                        type="password"
                        value={category.rcon_password}
                        onChange={handleChange}
                        placeholder="Hasło RCON"
                    />
                </div>
                <div className="text-right">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        type="button"
                        onClick={handleSubmit}
                    >
                        Zapisz
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CategoryAdd;
