import React from 'react';
import { useParams } from 'react-router-dom';
import {toast} from "react-toastify";
import { useNavigate } from 'react-router-dom';
const CategoryEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [category, setCategory] = React.useState({
        id: 0,
        name: "",
        rcon_ip: "",
        rcon_port: "",
        rcon_password: "",
        active: 0
    })
    React.useEffect(() => {
        fetch(`https://tipon.pl/api/category/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then(response => response.json())
            .then(data => {
                setCategory(data)
            }).catch(err => {
            toast.error("Wystąpił nieznany błąd")
            console.log(err)
        })
    }, [id])
    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setCategory(prevCategory => ({
            ...prevCategory,
            [id]: type === 'checkbox' ? checked : value
        }));
    };
    const validate = () => {
        let isValid = true;
        if (!category.name || category.name.length > 50) {
            toast.error('Nazwa Kategorii nie może mieć więcej niż 50 znaków');
            isValid = false;
        }
        if (!category.rcon_ip || !/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(category.rcon_ip)) {
            toast.error('IP RCON musi być poprawnym adresem IP');
            isValid = false;
        }
        if (!category.rcon_port || !/^\d+$/.test(category.rcon_port)) {
            toast.error('Port RCON musi być liczbą całkowitą');
            isValid = false;
        }
        if (!category.rcon_password || category.rcon_password.length > 350) {
            toast.error('Hasło RCON nie może mieć więcej niż 50 znaków');
            isValid = false;
        }
        return isValid;
    };
    const handleSave = () => {
        if (validate()) {
            fetch(`https://tipon.pl/api/category/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify(category)
            }).then(response => {
                if (response.ok) {
                    toast.success("Pomyślnie zapisano kategorię");
                    navigate("/category");
                }
            }).catch(err => {
                toast.error("Wystąpił nieznany błąd")
                console.log(err)
            })
        }
    };

    return (
        <div className="bg-gray-800 p-10 rounded-lg shadow">
            <h2 className="text-3xl font-semibold mb-6">Edycja Kategorii</h2>
            <form>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="name">
                        Nazwa Kategorii
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="name"
                        value={category.name}
                        onChange={handleChange}
                        type="text"
                        placeholder="Nazwa Kategorii"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="rconIp">
                        IP RCON
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="rcon_ip"
                        value={category.rcon_ip}
                        onChange={handleChange}
                        type="text"
                        placeholder="IP RCON"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="rconPort">
                        Port RCON
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="rcon_port"
                        type="text"
                        value={category.rcon_port}
                        onChange={handleChange}
                        placeholder="Port RCON"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="rconPassword">
                        Hasło RCON
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="rcon_password"
                        type="password"
                        value={category.rcon_password}
                        onChange={handleChange}
                        placeholder="Hasło RCON"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2">
                        Aktywny
                    </label>
                    <label className="flex items-center cursor-pointer">
                        <div className="relative">
                            <input id="active" type="checkbox" checked={category.active} onChange={handleChange} className="sr-only"/>
                            <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                            <div
                                className="dot absolute left-1 top-1 bg-gray-400 w-6 h-6 rounded-full transition"></div>
                        </div>
                    </label>
                </div>
                <div className="text-right">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        type="button"
                        onClick={handleSave}
                    >
                        Zapisz
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CategoryEdit;
