import React, { useState, useEffect, useRef } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";

const Navbar = () => {
    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    const handleLogout = () => {
        fetch("https://tipon.pl/api/auth/logout", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "token":localStorage.getItem('token')
            })
        }).then((response) => response.json())
            .then(() => {
                localStorage.removeItem('token')
                localStorage.removeItem('avatar')
                localStorage.removeItem('firstname')
                localStorage.removeItem('lastname')
                localStorage.removeItem('email')
                navigate('/auth/login')
                toast.success("Wylogowano pomyślnie!")
            })
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);
    let av = localStorage.getItem("avatar")
    let fname = localStorage.getItem("firstname")
    let lname = localStorage.getItem("lastname")
    let email = localStorage.getItem("email")
    return (
        <nav className="bg-gray-800 p-2 flex justify-between items-center sticky top-0 z-10">
            <div className="flex items-center px-8">
                <img src="https://cms.mscode.pl/uploads/image_1_57f1319056.png" className="w-10 h-10 mr-3" />
                <span className="text-2xl font-semibold">Tipon.pl</span>
            </div>
            <div className="relative" ref={dropdownRef}>
                <div
                    className="flex items-center p-2 rounded hover:bg-gray-700 cursor-pointer"
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                >
                    <img src={av} className="w-8 h-8 rounded-full mr-2" />
                    <div className="flex flex-col text-left">
                        <span className="mr-2">{fname} {lname}</span>
                        <span className="text-sm text-gray-400">{email}</span>
                    </div>
                </div>
                {dropdownVisible && (
                    <div className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-lg shadow-lg">
                        <Link
                            to="/settings"
                            className="block px-4 py-2 hover:bg-gray-600 rounded-lg"
                            onClick={() => setDropdownVisible(false)}
                        >
                            Ustawienia
                        </Link>
                        <div
                            className="block px-4 py-2 hover:bg-gray-600 rounded-lg"
                            onClick={() =>
                            {
                                setDropdownVisible(false)
                                handleLogout()
                            }}
                        >
                            Wyloguj
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
