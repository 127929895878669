import React from 'react';

const Card = ({ icon, title, value }) => {
    return (
        <div className="bg-gray-800 p-5 rounded-lg shadow hover:bg-gray-700 transition">
            <div className="flex flex-col items-center">
                <span className="material-icons text-4xl mb-4">{icon}</span>
                <div className="text-center">
                    <h3 className="text-md mb-2">{title}</h3>
                    <p className="text-gray-400 text-lg">{value}</p>
                </div>
            </div>
        </div>
    );
}

export default Card;
