import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {toast} from "react-toastify";
import { useNavigate } from 'react-router-dom';
const ProductEdit = () => {
    // eslint-disable-next-line no-template-curly-in-string
    const message = `Dostępne zmienne - $\{ITEMID} oznaczający ID produktu oraz $\{ITEMNICK} oznaczający nick donateora oraz $\{ITEMAMOUNT} oznaczający ilość zakupionego przedmiotu `
    const navigate = useNavigate();
    const { id } = useParams();
    const [product, setProduct] = React.useState({
        id: 0,
        name: "",
        price: 0,
        maxamount: null,
        description: "",
        active: 0,
        image: "",
        command: "",
        updatedAt: "",
        CategoryId: 1
    })
    const [categories, setCategories] = React.useState([]);
    useEffect(() => {
        fetch("https://tipon.pl/api/category/", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then(response => response.json())
            .then(data => {
                setCategories(data)
            }).catch(err => {
            toast.error("Wystąpił nieznany błąd")
            console.log(err)
        })
        fetch(`https://tipon.pl/api/product/${id}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        })
            .then(response => response.json())
            .then(data => {
                setProduct(data);
            })
            .catch(error => console.error('Błąd podczas requestu API:', error));
    }, [id]);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setProduct(prevProduct => ({
            ...prevProduct,
            [id]: type === 'checkbox' ? checked : value
        }));
    };

    const validate = () => {
        let isValid = true;

        if (!product.name || product.name.length > 50) {
            toast.error('Nazwa Produktu nie może mieć więcej niż 50 znaków');
            isValid = false;
        }

        const urlPattern = /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|bmp|webp|svg))$/i;
        if (!urlPattern.test(product.image)) {
            toast.error('Zdjęcie musi być poprawnym URL i kończyć się rozszerzeniem obrazka');
            isValid = false;
        }

        if (!product.price || !/^\d+(\.\d{1,2})?$/.test(product.price)) {
            toast.error('Cena musi być liczbą dziesiętną w formacie 9.50, 9.00 itp.');
            isValid = false;
        }

        const maxQuantityValue = parseInt(product.maxamount, 10);
        if (isNaN(maxQuantityValue) || maxQuantityValue > 255) {
            toast.error('Maksymalna liczba musi być liczbą i nie może być większa niż 255');
            isValid = false;
        }
        

        return isValid;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        fetch(`https://tipon.pl/api/product/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(product)
        })
            .then(response => response.json())
            .then(()=> {
                toast.success("Produkt zaktualizowany!")
                navigate("/product")
            })
            .catch(error => console.error('Error updating product:', error));
    };
    return (
        <div className="bg-gray-800 p-10 rounded-lg shadow">
            <h2 className="text-3xl font-semibold mb-6">Edycja Produktu</h2>
            <form>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="name">
                        Nazwa Produktu
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="name"
                        value={product.name}
                        type="text"
                        placeholder="Nazwa Produktu"
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="imageUrl">
                        Zdjęcie (URL)
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="image"
                        type="text"
                        value={product.image}
                        placeholder="URL Zdjęcia"
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="price">
                        Cena
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="price"
                        value={product.price}
                        type="number"
                        placeholder="Cena"
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="maxQuantity">
                        Maksymalna liczba
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="maxamount"
                        value={product.maxamount}
                        onChange={handleChange}
                        placeholder="Maksymalna liczba"
                    />
                    <div className="mt-2">
                        Ustawienie wartości 0 będzie oznaczać brak limitu
                    </div>
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="maxQuantity">
                        Komenda
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="command"
                        value={product.command}
                        onChange={handleChange}
                        placeholder="Maksymalna liczba"
                    />
                    <div className="mt-2">
                        {message}
                    </div>
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="CategoryId">
                        Kategoria
                    </label>
                    <select
                        id="CategoryId"
                        value={product.CategoryId}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name} - ID:{category.id}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2">
                        Aktywny
                    </label>
                    <label className="flex items-center cursor-pointer">
                        <div className="relative">
                            <input id="active" type="checkbox" checked={product.active} onChange={handleChange}
                                   className="sr-only"/>
                            <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                            <div
                                className="dot absolute left-1 top-1 bg-gray-400 w-6 h-6 rounded-full transition"></div>
                        </div>
                    </label>
                </div>
                <div className="text-right">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        type="button"
                        onClick={handleSubmit}
                    >
                        Zapisz
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ProductEdit;
