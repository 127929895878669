import React, {useState} from 'react';
import DataTable from 'react-data-table-component';
import DetailsModal from "../../components/DetailsModal";
import {toast} from "react-toastify";
const customStyles = {
    table: {
        style: {
            color: '#4A5568',
            backgroundColor: '#4A5568',
        },
    },
    expanderCell: {
        style: {
            flex: '0 0 48px',
        },
    },
    header: {
        style: {
            backgroundColor: '#2D3748',
            color: '#FFFFFF',
            borderRadius: '20px 20px 0px 0px'
        },
    },
    headRow: {
        style: {
            backgroundColor: '#2D3748',
            color: '#FFFFFF',
            borderBottomWidth: '0px',
            borderBottomColor: '#4A5568',
        },
    },
    headCells: {
        style: {
            color: '#FFFFFF',
        },
    },
    cells: {
        style: {
            backgroundColor: '#1A202C',
            color: '#FFFFFF',
            borderBottomColor: '#1A202C',
            padding: '16px',
        },
    },
    rows: {
        style: {
            minHeight: '72px',
            borderBottomWidth: '0px',
            borderBottomStyle: 'none',
            '&:not(:last-of-type)': {
                borderBottomStyle: 'none',
                borderBottomWidth: '0px',
            },
            '&:last-of-type': {
                borderBottomStyle: 'none',
                borderBottomWidth: '0px',
            },
        },
        highlightOnHoverStyle: {
            backgroundColor: '#4A5568',
            color: '#FFFFFF',
            transitionDuration: '0.15s',
            transitionProperty: 'background-color',
            borderBottomColor: '#4A5568',
        },
    },
    pagination: {
        style: {
            backgroundColor: '#1A202C',
            color: '#FFFFFF',
            borderTopWidth: '1px',
            borderTopColor: '#4A5568',
        },
        pageButtonsStyle: {
            color: '#FFFFFF',
            fill: '#FFFFFF',
            '&:hover:not(:disabled)': {
                backgroundColor: '#4A5568',
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: '#4A5568',
            },
        },
    },
};

const paginationComponentOptions = {
    rowsPerPageText: 'Wierszy na stronę',
    rangeSeparatorText: 'z',
    noRowsPerPage: false,
    selectAllRowsItem: false,
};

const OrdersList = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    React.useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true);
            try {
                const response = await fetch("https://tipon.pl/api/order", {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                });
                const data = await response.json();
                const sortedData = data.sort((a, b) => new Date(b.order.createdAt) - new Date(a.order.createdAt));
                setOrders(sortedData);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchOrders();
    }, []);
    const handleOpenModal = (order) => {
        setSelectedOrder(order);
        setModalIsOpen(true);
    };
    const redoOrder = async (order) => {
        try {
            const response = await fetch(`https://tipon.pl/api/order/redo/${order.order.id}`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });
            toast.success(`Wysłano zadanie do kolejki`)

        } catch (error) {
            toast.error(`Jednak coś poszło nie tak`)
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setSelectedOrder(null);
    };


    const columns = [
        {
            name: '#',
            selector: row => <p className="text-base">{row.order.id}</p>,
            sortable: true,
        },
        {
            name: 'Zamawiający',
            selector: row => row.order.nick,
            sortable: true,
        },
        {
          name: "Wiadomość",
          selector: row => row.order.message.length > 50 ? row.order.message.substring(0, 47) + '...' : row.order.message,
          sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                <>
                    {
                        {
                            'completed':
                                <span
                                    className="inline-flex items-center rounded-md bg-green-200 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                <span className="material-icons text-sm mr-1">check</span> ZREALIZOWANE
                            </span>,
                            'cancelled':
                                <span
                                    className="inline-flex items-center rounded-md bg-red-200 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                <span className="material-icons text-sm mr-1">close</span> ANULOWANE
                            </span>,
                            'pending':
                                <span
                                    className="inline-flex items-center rounded-md bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">
                                <span className="material-icons text-sm mr-1">pending</span> W TOKU
                            </span>,
                        }[row.order.status]
                    }
                </>
            ),
        },
        {
            name: 'Data zamówienia',
            selector: row => new Date(row.order.createdAt).toLocaleDateString('pl-PL') + ' ' + new Date(row.order.createdAt).toLocaleTimeString('pl-PL'),
            sortable: false,
            sortField: 'createdAt',
        },
        {
            name: ' ',
            cell: row => (
                <div className="flex items-center gap-x-2">
                    <button className="text-blue-500 hover:text-blue-700 flex items-center" onClick={() => handleOpenModal(row)}>
                        <span className="material-icons mr-1">info</span> Szczegóły
                    </button>
                    <button className="text-blue-500 hover:text-blue-700 flex items-center" onClick={() => redoOrder(row)}>
                        <span className="material-icons mr-1">restart_alt</span>
                    </button>
                </div>
            )
        },
    ];

    if (loading) return <div>Ładowanie...</div>;
    if (error) return <div>Błąd: {error}</div>;

    return (
        <div className="bg-gray-800 p-5 rounded-lg shadow mt-10">
            <h2 className="text-3xl font-semibold mb-6">Zamówienia</h2>
            <p className="text-gray-400 mb-4">Lista wszystkich zamówień</p>
            <DataTable
                columns={columns}
                data={orders}
                customStyles={customStyles}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                defaultSortField="createdAt"
                defaultSortAsc={false}
            />
            <DetailsModal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                order={selectedOrder}
            />
        </div>
    );
}

export default OrdersList;
