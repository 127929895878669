import React from 'react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
// eslint-disable-next-line no-template-curly-in-string
const message = `Dostępne zmienne - $\{ITEMID} oznaczający ID produktu oraz $\{ITEMNICK} oznaczający nick donateora oraz $\{ITEMAMOUNT} oznaczający ilość zakupionego przedmiotu `
const ProductAdd = () => {
    const navigate = useNavigate();
    const [product, setProduct] = React.useState({
        name: "",
        price: 0,
        maxamount: null,
        description: "",
        active: 0,
        image: "",
        command: "",
        updatedAt: "",
        CategoryId: 1
    })
    const [categories, setCategories] = React.useState([]);
    React.useEffect(() => {
        fetch("https://tipon.pl/api/category/", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then(response => response.json())
            .then(data => {
                if (data.length === 1) {
                    setProduct(prevProduct => ({
                        ...prevProduct,
                        CategoryId: data[0].id
                    }));
                }
                setCategories(data)
            }).catch(err => {
            toast.error("Wystąpił nieznany błąd")
            console.log(err)
        })
    },[])
    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setProduct(prevProduct => ({
            ...prevProduct,
            [id]: type === 'checkbox' ? checked : value
        }));
    };

    const validate = () => {
        let isValid = true;

        if (!product.name || product.name.length > 50) {
            toast.error('Nazwa Produktu nie może mieć więcej niż 50 znaków');
            isValid = false;
        }

        const urlPattern = /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|bmp|webp|svg))$/i;
        if (!urlPattern.test(product.image)) {
            toast.error('Zdjęcie musi być poprawnym URL i kończyć się rozszerzeniem obrazka');
            isValid = false;
        }

        if (!product.price || !/^\d+(\.\d{1,2})?$/.test(product.price)) {
            toast.error('Cena musi być liczbą dziesiętną w formacie 9.50, 9.00 itp.');
            isValid = false;
        }

        const maxQuantityValue = parseInt(product.maxamount, 10);
        if (isNaN(maxQuantityValue) || maxQuantityValue > 255) {
            toast.error('Maksymalna liczba musi być liczbą i nie może być większa niż 255');
            isValid = false;
        }
        
        

        return isValid;
    };
    
    const handleSubmit = () => {
        if (!validate()) {
            return;
        }

        fetch(`https://tipon.pl/api/product/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({ "list": [product]})
        })
            .then(response => response.json())
            .then(() => {
                toast.success("Utworzono produkt!")
                //navigate("/product")
            })
            .catch(error => console.error('Błąd podczas dodawania produktu:', error));
    };
    return (
        <div className="bg-gray-800 p-10 rounded-lg shadow">
            <h2 className="text-3xl font-semibold mb-6">Dodawanie produktu</h2>
            <form>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="name">
                        Nazwa Produktu
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="name"
                        type="text"
                        value={product.name}
                        onChange={handleChange}
                        placeholder="Nazwa Produktu"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="imageUrl">
                        Zdjęcie (URL)
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="image"
                        type="text"
                        value={product.image}
                        onChange={handleChange}
                        placeholder="URL Zdjęcia"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="price">
                        Cena
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="price"
                        type="number"
                        step="0.01"
                        value={product.price}
                        onChange={handleChange}
                        placeholder="Cena"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="maxQuantity">
                        Maksymalna liczba
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="maxamount"
                        value={product.maxamount}
                        onChange={handleChange}
                        type="number"
                        step="1"
                        placeholder="Maksymalna liczba"
                    />
                    <div className="mt-2">
                        Ustawienie wartości 0 będzie oznaczać brak limitu
                    </div>
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="maxQuantity">
                        Komenda
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        id="command"
                        value={product.command}
                        onChange={handleChange}
                        placeholder="Maksymalna liczba"
                    />
                    <div className="mt-2">
                        {message}
                    </div>
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="CategoryId">
                        Kategoria
                    </label>
                    <select
                        id="CategoryId"
                        value={product.CategoryId}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name} - ID:{category.id}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="text-right">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        type="button"
                        onClick={handleSubmit}
                    >
                        Zapisz
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ProductAdd;
