import React from 'react';
import DataTable from 'react-data-table-component';


const columns = [
    {
        name: '#',
        selector: row => <p className="text-base">{row.id}</p>,
        sortable: true,
        grow: 0.5,
    },
    {
        name: 'ID Zamówienia',
        selector: row => <p className="text-base">{row.order_id}</p>,
        sortable: true,
        grow: 0.5,
    },
    {
        name: 'Wartość',
        selector: row => <p className={"font-bold text-lg"}>{row.amount} PLN</p>,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => (
            <>
                {
                    {
                        'completed':
                            <span
                                className="inline-flex items-center rounded-md bg-green-200 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                <span className="material-icons text-sm mr-1">check</span> ZAAKCEPTOWANA
                            </span>,
                        'cancelled':
                            <span
                                className="inline-flex items-center rounded-md bg-red-200 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                <span className="material-icons text-sm mr-1">close</span> ANULOWANA
                            </span>,
                        'pending':
                            <span
                                className="inline-flex items-center rounded-md bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">
                                <span className="material-icons text-sm mr-1">pending</span> W TOKU
                            </span>,
                    }[row.status]
                }
            </>
        ),
    },
    {
        name: 'Data utworzenia',
        selector: row => new Date(row.createdAt).toLocaleDateString('pl-PL') + ' ' + new Date(row.createdAt).toLocaleTimeString('pl-PL'),
        sortable: false,
        sortField: 'createdAt',
    },
];

const customStyles = {
    table: {
        style: {
            color: '#4A5568',
            backgroundColor: '#4A5568',
        },
    },
    expanderCell: {
        style: {
            flex: '0 0 48px',
        },
    },
    header: {
        style: {
            backgroundColor: '#2D3748',
            color: '#FFFFFF',
            borderRadius: '20px 20px 0px 0px'
        },
    },
    headRow: {
        style: {
            backgroundColor: '#2D3748',
            color: '#FFFFFF',
            borderBottomWidth: '0px',
            borderBottomColor: '#4A5568',
        },
    },
    headCells: {
        style: {
            color: '#FFFFFF',
        },
    },
    cells: {
        style: {
            backgroundColor: '#1A202C',
            color: '#FFFFFF',
            borderBottomColor: '#1A202C',
            padding: '16px',
        },
    },
    rows: {
        style: {
            minHeight: '72px',
            borderBottomWidth: '0px',
            borderBottomStyle: 'none',
            '&:not(:last-of-type)': {
                borderBottomStyle: 'none',
                borderBottomWidth: '0px',
            },
            '&:last-of-type': {
                borderBottomStyle: 'none',
                borderBottomWidth: '0px',
            },
        },
        highlightOnHoverStyle: {
            backgroundColor: '#4A5568',
            color: '#FFFFFF',
            transitionDuration: '0.15s',
            transitionProperty: 'background-color',
            borderBottomColor: '#4A5568',
        },
    },
    pagination: {
        style: {
            backgroundColor: '#1A202C',
            color: '#FFFFFF',
            borderTopWidth: '1px',
            borderTopColor: '#4A5568',
        },
        pageButtonsStyle: {
            color: '#FFFFFF',
            fill: '#FFFFFF',
            '&:hover:not(:disabled)': {
                backgroundColor: '#4A5568',
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: '#4A5568',
            },
        },
    },
};

const paginationComponentOptions = {
    rowsPerPageText: 'Wierszy na stronę',
    rangeSeparatorText: 'z',
    noRowsPerPage: false,
    selectAllRowsItem: false,
};

const ProductList = () => {
    const [payments, setPayments] = React.useState([{}])
    React.useEffect(() => {
        fetch('https://tipon.pl/api/payment', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then(response => response.json())
            .then(data => {
                const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setPayments(sortedData)
            })
            .catch(error => console.log('error', error));
    }, [])
    return (
        <div className="bg-gray-800 p-5 rounded-lg shadow mt-10">
            <h2 className="text-3xl font-semibold mb-6">Płatności</h2>
            <p className="text-gray-400 mb-4">Lista wszystkich płatności</p>
            <DataTable
                columns={columns}
                data={payments}
                customStyles={customStyles}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                defaultSortField="createdAt"
                defaultSortAsc={false}
            />
        </div>
    );
}

export default ProductList;
