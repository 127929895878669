import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Dashboard from "./pages/Dashboard";
import ProductEdit from "./pages/product/ProductEdit";
import ProductAdd from "./pages/product/ProductAdd";
import ProductList from "./pages/product/ProductList";
import Login from "./pages/auth/Login";
import CategoryEdit from "./pages/category/CategoryEdit";
import CategoryAdd from "./pages/category/CategoryAdd";
import CategoryList from "./pages/category/CategoryList";
import PaymentsList from "./pages/order/PaymentsList";
import OrdersList from "./pages/order/OrdersList";
import Settings from "./pages/settings/Settings";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Error404 from "./pages/errors/Error404";
const Content = () => {
    const checkIfLoggedIn = () => {
        if (!localStorage.getItem('token')) return (
            <Navigate to="/auth/login" />
        )
        return ""
    }

    return (
        <>
            <ToastContainer
                position={"top-center"}
            theme={"dark"}/>
                <Routes>
                    <Route path="/auth/login" element={<Login />} />
                    <Route path="/404" element={<Error404 />} />
                    <Route path="*" element={
                        <div className="flex flex-col h-screen bg-gray-900 text-white">
                            {checkIfLoggedIn()}
                            <Navbar />
                            <div className="flex flex-1 overflow-hidden">
                                <Sidebar />
                                <main className="flex-1 p-10 overflow-auto">
                                    <Routes>
                                        <Route exact path="/" element={
                                            <Dashboard />
                                        } />
                                        <Route path="/product/edit/:id" element={
                                            <ProductEdit />
                                        } />
                                        <Route path="/product/add" element={
                                            <ProductAdd />
                                        } />
                                        <Route path="/product" element={
                                            <ProductList />
                                        } />
                                        <Route path="/category/edit/:id" element={
                                            <CategoryEdit />
                                        } />
                                        <Route path="/category/add" element={
                                            <CategoryAdd />
                                        } />
                                        <Route path="/category" element={
                                            <CategoryList />
                                        } />
                                        <Route path="/payment" element={
                                            <PaymentsList />
                                        } />
                                        <Route path="/order" element={
                                            <OrdersList />
                                        } />
                                        <Route path="/settings" element={
                                            <Settings />
                                        } />
                                        <Route path="*" element={
                                            <Navigate to="/404" />
                                        } />
                                    </Routes>
                                </main>
                            </div>
                        </div>
                    } />
                </Routes>
        </>
    );
}

const App = () => {
    return (
            <Router>
                <Content />
            </Router>
    );
}

export default App;
