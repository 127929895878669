import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {toast} from "react-toastify";


const ProductList = () => {
    const [products, setProducts] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null);
    const handleDelete = (productId) => {

        const isConfirmed = window.confirm("Czy napewno chcesz usunąć ten produkt?");
        if (isConfirmed) {
            fetch(`https://tipon.pl/api/product/${productId}`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }).then(response => {
                if (response.ok) {
                    toast.success("Usunięto produkt!");
                }
            }).catch(error => {
                console.error("Error deleting product:", error);
            });
        }
    };
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleSelectProduct = (productId) => {
        setSelectedProducts(prevSelected =>
            prevSelected.includes(productId)
                ? prevSelected.filter(id => id !== productId)
                : [...prevSelected, productId]
        );
    };

    const handleSelectAllProducts = () => {
        if (selectedProducts.length === products.length) {
            setSelectedProducts([]);
        } else {
            setSelectedProducts(products.map(product => product.id));
        }
    };
    const handleBulkDelete = () => {
        const isConfirmed = window.confirm("Czy napewno chcesz usunąć zaznaczone produkty?");
        if (isConfirmed) {
            const requests = selectedProducts.map(productId =>
                fetch(`https://tipon.pl/api/product/${productId}`, {
                    method: "DELETE",
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                })
            );
            Promise.all(requests).then(responses => {
                const allSucceeded = responses.every(response => response.ok);
                if (allSucceeded) {
                    toast.success("Zaznaczone produkty pomyślnie usunięte");
                    setProducts(products.filter(product => !selectedProducts.includes(product.id)));
                    setSelectedProducts([]);
                } else {
                    toast.error("Wystąpił błąd podczas usuwania produktów");
                }
            }).catch(error => {
                console.error(error);
                toast.error("Wystąpił błąd podczas usuwania produktów");
            });
        }
    };
    const resetBackend = () => {
        fetch("https://tipon.pl/api/order/sdfgopji", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then(response => response.json())
            .then(data => {
                if (data.length === 1) {
                    toast.success("Restartowanie serwera, proszę czekać")
                }
            }).catch(err => {
            toast.error("Wystąpił nieznany błąd")
            console.log(err)
        })
    }
    const columns = [
        {
            name: <input type="checkbox" checked={selectedProducts.length === products.length} onChange={handleSelectAllProducts} />,
            cell: row => <input type="checkbox" checked={selectedProducts.includes(row.id)} onChange={() => handleSelectProduct(row.id)} />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: "56px",
        },
        {
            name: 'Nazwa produktu',
            selector: row => (
                <div className="flex items-center">
                    <img src={row.image} alt={row.name} className="w-10 h-10 rounded-full mr-4" />
                    <div>
                        <p className="text-base">{row.name}</p>
                    </div>
                </div>
            ),
            sortable: true,
            grow: 2,
        },
        {
            name: 'Cena',
            selector: row => row.price,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                <span
                    className={`${row.active === 1 ? 'inline-flex items-center rounded-md bg-green-200 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20' : 'inline-flex items-center rounded-md bg-red-200 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'}`}>
                <span className="material-icons text-sm mr-1">{`${row.active === 1 ? 'check' : 'close'}`}</span>
                    {row.active === 1 ? "AKTYWNY" : "NIEAKTYWNY"}
            </span>
            ),
        },
        {
            name: 'Data utworzenia',
            selector: row => new Date(row.createdAt).toLocaleDateString('pl-PL') + ' ' + new Date(row.createdAt).toLocaleTimeString('pl-PL'),
            sortable: true,
        },
        {
            name: 'Akcje',
            cell: row => (
                <div className="flex items-center">
                    <Link to={`/product/edit/${row.id}`} className="mr-2">
                        <button className="text-blue-500 hover:text-blue-700 flex items-center">
                            <span className="material-icons mr-1">edit</span> Edytuj
                        </button>
                    </Link>
                    <button className="text-red-500 hover:text-red-700 flex items-center" onClick={() => handleDelete(row.id)}>
                        <span className="material-icons mr-1">delete</span> Usuń
                    </button>
                </div>
            ),
        },
    ];

    const customStyles = {
        table: {
            style: {
                color: '#4A5568',
                backgroundColor: '#4A5568',
            },
        },
        expanderCell: {
            style: {
                flex: '0 0 48px',
            },
        },
        header: {
            style: {
                backgroundColor: '#2D3748',
                color: '#FFFFFF',
                borderRadius: '20px 20px 0px 0px'
            },
        },
        headRow: {
            style: {
                backgroundColor: '#2D3748',
                color: '#FFFFFF',
                borderBottomWidth: '0px',
                borderBottomColor: '#4A5568',
            },
        },
        headCells: {
            style: {
                color: '#FFFFFF',
            },
        },
        cells: {
            style: {
                backgroundColor: '#1A202C',
                color: '#FFFFFF',
                borderBottomColor: '#1A202C',
                padding: '16px',
            },
        },
        rows: {
            style: {
                minHeight: '72px',
                borderBottomWidth: '0px',
                borderBottomStyle: 'none',
                '&:not(:last-of-type)': {
                    borderBottomStyle: 'none',
                    borderBottomWidth: '0px',
                },
                '&:last-of-type': {
                    borderBottomStyle: 'none',
                    borderBottomWidth: '0px',
                },
            },
            highlightOnHoverStyle: {
                backgroundColor: '#4A5568',
                color: '#FFFFFF',
                transitionDuration: '0.15s',
                transitionProperty: 'background-color',
                borderBottomColor: '#4A5568',
            },
        },
        pagination: {
            style: {
                backgroundColor: '#1A202C',
                color: '#FFFFFF',
                borderTopWidth: '1px',
                borderTopColor: '#4A5568',
            },
            pageButtonsStyle: {
                color: '#FFFFFF',
                fill: '#FFFFFF',
                '&:hover:not(:disabled)': {
                    backgroundColor: '#4A5568',
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: '#4A5568',
                },
            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Wierszy na stronę',
        rangeSeparatorText: 'z',
        noRowsPerPage: false,
        selectAllRowsItem: false,
    };


    useEffect(() => {
        fetch("https://tipon.pl/api/category/", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }).then((response) => response.json())
            .then((data) => {
               setCategories(data)
            })
        console.log(categories)
        fetch("https://tipon.pl/api/product/", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }).then((response) => response.json())
            .then((data) => {
                if (selectedCategory) {
                    setProducts(data.filter(product => product.CategoryId === parseInt(selectedCategory)));
                } else {
                    setProducts(data)
                }
            })
    }, [selectedCategory]);

    return (
        <div className="bg-gray-800 p-5 rounded-lg shadow mt-10">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-3xl font-semibold">Produkty</h2>
                <div className="flex gap-x-2">
                    <select
                        onChange={handleCategoryChange}
                        className="bg-gray-700 text-white font-bold py-2 px-4 rounded mr-4"
                    >
                        <option value="">Wszystkie kategorie</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                    <Link to="/product/add"
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center mr-4">
                        <span className="material-icons mr-1 text-lg">add_circle</span> Dodaj
                    </Link>
                    <button
                        onClick={handleBulkDelete}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                        <span className="material-icons mr-1 text-lg">delete</span> Usuń zaznaczone
                    </button>
                    <button
                        onClick={resetBackend}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                        <span className="material-icons mr-1 text-lg">restart_alt</span> Zrestartuj serwer
                    </button>
                </div>
            </div>
            <p className="text-gray-400 mb-4">Lista wszystkich produktów</p>
            <DataTable
                columns={columns}
                data={products}
                customStyles={customStyles}
                pagination
                paginationComponentOptions={paginationComponentOptions}
            />
        </div>
    );
}

export default ProductList;