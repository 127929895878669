import React, { useState } from 'react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const Settings = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [time, setTime] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState('');
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

    const handleAudioFileChange = (e) =>  {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('mp3file', file);

        fetch("https://tipon.pl/api/music", {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: formData
        }).then(response => response.json())
            .then(() => {
                toast.success("Plik audio został przesłany!")
            }).catch(err => {
            console.log(err)
        });
    };

    const handleChangeDonationTime = (e) => {
        fetch("https://tipon.pl/api/music/length", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                length: time
            })
        }).then(response => response.json())
            .then(() => {
                toast.success("Czas donate został zmieniony!")
            }).catch(err => {
                console.log(err)
        })
    }

    const handleInputDonationTime = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 3) {
            setTime(inputValue);
        } else {
            toast.error("Długość przekracza liczbę cyfr 3");
        }
    }

    const handleSaveAccountSettings = () => {
        fetch("https://tipon.pl/api/auth/changepassword", {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                email: localStorage.getItem('email'),
                old_password: password,
                new_password: confirmPassword
            })
        }).then(response => {
            if (response.status === 403) {
                toast.error("Błędne hasło!");
                navigate('/auth/login');
                return;
            }
            return response.json();
        })
            .then(data => {
                if (data) {
                    toast.success("Hasło zostało zmienione! Za chwilę zostaniesz wylogowany!");
                    setTimeout(() => {
                        fetch("https://tipon.pl/api/auth/logout", {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "token":localStorage.getItem('token')
                            })
                        });
                        localStorage.removeItem('token');
                        localStorage.removeItem('avatar');
                        localStorage.removeItem('firstname');
                        localStorage.removeItem('lastname');
                        localStorage.removeItem('email');
                        navigate('/auth/login');
                    }, 3000);
                }
            }).catch(err => {
            console.log(err);
        });
    };

    const handleTestDonate = () => {
        fetch("https://tipon.pl/api/notification", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then(response => response.json())
            .then(() => {
                toast.success("Testowy donate został wysłany!")
            }).catch(err => {
                console.log(err)
            })
    };
    React.useEffect(() => {
       setEmail(localStorage.getItem('email') )
    },[])

    return (
        <div className="bg-gray-800 p-5 rounded-lg shadow mt-10">
            <h2 className="text-3xl font-semibold mb-6">Ustawienia</h2>

            <div className="mb-10 p-10">
                <h3 className="text-2xl font-semibold mb-4">Ustawienia konta</h3>
                <div className="mb-4">
                    <label className="block text-gray-400 mb-1">Email</label>
                    <input
                        type="email"
                        value={email}
                        readOnly
                        onChange={handleEmailChange}
                        className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-400 mb-1">Obecne hasło</label>
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-400 mb-1">Nowe hasło</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                </div>
                <button
                    onClick={handleSaveAccountSettings}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Zapisz
                </button>
            </div>

            <div className="mb-10 p-10">
                <h3 className="text-2xl font-semibold mb-4">Ustawienia donate</h3>
                <div className="mb-4">
                    <label className="block text-gray-400 mb-1">Prześlij plik audio do donate</label>
                    <input
                        type="file"
                        accept="audio/*"
                        onChange={handleAudioFileChange}
                        className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-400 mb-1">Ustaw czas wyświetlania donate w sekundach </label>
                    <input
                        type="number"
                        onChange={handleInputDonationTime}
                        className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                    <button
                        onClick={handleChangeDonationTime}
                        className="inline mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Zapisz
                    </button>
                </div>
                <button
                    onClick={handleTestDonate}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                    Wyślij testowy donate
                </button>
            </div>
        </div>
    );
};

export default Settings;
